<template>
  <p-modal :visible="true">
    <div slot="title" class="popup-title">
      Edit milestone
      <p-button variant="text" style="font-size: 2rem" @click="$emit('close')">&times;</p-button>
    </div>
    <div class="milestone-edit-popup">
      <div class="template-title-row">
        <h2 class="row-title">Template title</h2>
        <p-text-field v-model="title" :disabled="!$hasPermission('workflows')" />
      </div>

      <div class="template-title-row">
        <MilsestoneTags v-model="tags" :disabled="!$hasPermission('workflows')" />
      </div>
      <div class="template-title-row">
        <UniquenessDropdown v-model="uniqueness" :disabled="!$hasPermission('workflows')" />
      </div>
      <h2 class="row-title">Events:</h2>
      <milestone-part
        v-for="(event, index) in events"
        :key="event.code"
        :event="event"
        :is-opened="opened.includes(index)"
        @input="($e) => onEventChange(index, $e)"
        @remove="removeEvent(event)"
        @predicateChanged="($e) => onPredicateChanged(index, $e)"
        @openedStateChange="onAccordionClick(index)"
        @creationEventChanged="($e) => onCreationEventChanged(index, $e)"
      />
      <div>
        <div v-if="remainingEvents.length" class="addHandler">
          <p-multiselect
            :disabled="!$hasPermission('workflows')"
            :options="remainingEvents"
            :get-label-callback="(source) => source.name"
            :value="addHandler || remainingEvents[0]"
            @input="onRemainingChange"
          />
          <p-button v-if="$hasPermission('workflows')" @click="onRemainingClick">Add</p-button>
        </div>
      </div>
    </div>
    <div class="footer" slot="footer">
      <p-button @click="$emit('close')">Cancel</p-button>
      <p-button v-if="$hasPermission('workflows')" color="secondary" @click="save">Ok</p-button>
    </div>
  </p-modal>
</template>
<script>
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';

import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import MilestonePart from './MilestonePart.vue';
import TextField from '@/components/common/TextField';
import UniquenessDropdown from './wizard-parts/UniquenessDropdown';

import MilsestoneTags from './wizard-parts/MilsestoneTags';

export default {
  components: {
    'p-modal': Modal,
    'p-button': Button,
    'p-multiselect': Multiselect,
    'p-text-field': TextField,
    MilestonePart,
    UniquenessDropdown,
    MilsestoneTags
  },
  props: {
    milestone: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      title: this.milestone.title,
      events: [...JSON.parse(JSON.stringify(this.milestone.events))],
      opened: [],
      addHandler: null,
      uniqueness: this.milestone.uniqueness,
      tags: this.milestone.tags || []
    };
  },
  computed: {
    ...mapState({
      eventSources: (s) => s.milestones.contracts.eventSources
    }),
    remainingEvents: function () {
      const createEvent = this.events.find((e) => e.type === 'create');
      return this.eventSources.reduce((acc, curr) => {
        if (curr.type !== 'external') {
          return acc;
        }

        const events = curr.events.filter((event) => event.code !== createEvent.code);
        acc.push(...events);
        return acc;
      }, []);
    }
  },
  methods: {
    save() {
      if (
        this.milestone.title !== this.title ||
        JSON.stringify(this.milestone.events) !== JSON.stringify(this.events) ||
        this.uniqueness !== this.milestone.uniqueness ||
        JSON.stringify(this.milestone.tags) !== JSON.stringify(this.tags)
      ) {
        const data = {
          title: this.title,
          events: this.events,
          uniqueness: this.uniqueness,
          tags: this.tags
        };
        this.$store.commit('milestones/PROPERTIES_CHANGED', { data });
      }
      this.$emit('close');
    },
    onAccordionClick(event) {
      const index = this.opened.findIndex((name) => name === event);
      if (index === -1) {
        this.opened.push(event);
      } else {
        this.opened.splice(index, 1);
      }
    },
    onRemainingClick() {
      const remaining = this.addHandler || this.remainingEvents[0];
      this.events.push({
        code: remaining.code,
        type: 'update',
        action: {
          delete: false,
          finish: false,
          title: this.events[0].action.title,
          dueAt: this.events[0].action.dueAt,
          clientDueDate: this.events[0].action.clientDueDate,
          assignees: this.events[0].action.assignees,
          notes: this.events[0].action.notes ? [...this.events[0].action.notes] : []
        }
      });
      this.opened = [remaining.code];
      this.addHandler = null;
    },
    onRemainingChange(e) {
      this.addHandler = e;
    },
    removeEvent(event) {
      const index = this.events.findIndex((e) => e === event);
      if (index > -1) {
        this.events.splice(index, 1);
      }
    },
    onEventChange(index, $e) {
      this.events[index].action = { ...$e };
    },
    onPredicateChanged(index, $e) {
      this.events[index].predicates = [...$e];
    },
    onCreationEventChanged(index, $e) {
      this.events[index].code = $e.code;
      this.events[index].predicates = [];
    }
  }
};
</script>
<style lang="scss" scoped>
.milestone-edit-popup {
  padding: 20px;
}
.popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer {
  button {
    margin-right: 20px;
  }
}
.addHandler {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  span {
    padding: 10px;
  }
  .multiselect-wrapper {
    flex: 0 0 60%;
  }
}
.row-title {
  margin-bottom: 10px;
}
.template-title-row {
  margin-bottom: 20px;
}
</style>
